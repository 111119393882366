<template>
  <div class="base-box">
    <UploadInfoCom 
      @uploadSuccess="uploadSuccessCallBack" :options="options" />
    <SearchInput
      :placeHolder="placeHolder"
      :asyncUrl='asyncUrl'
      @searchInput="searchInput"
    />
    <SwitchTable 
      @changeAsyncType="changeAsyncType"
      :asyncInfo="asyncInfo"
    />
    <TableList
      v-if="showCom"
      :dataList="dataList"
      :total="total"
      :pageNum="pageNum"
      :pageSize="pageSize"
      :listType="listType"
      @operate="operate"
      @currentPageChange="currentPageChange"
      @pageSizeChange="pageSizeChange"
    />
  </div>
</template>

<script>
import UploadInfoCom from '@/components/UploadInfoCom/Index.vue'
import TableList from '@/components/UploadInfoCom/TableList.vue'
import SearchInput from '@/components/SearchInput/Index.vue'
import SwitchTable from '@/components/UploadInfoCom/SwitchTable.vue'
export default {
  name: "mainContract",
  data() {
    return{
      placeHolder: '请输入合同名称、经办人、签约主体关键词进行搜索',
      dataList: [],
      asyncInfo: {}, //切换组件数据
      total: 0,
      pageNum: 1,
      pageSize: 10,
      companyName: '',
      listType: '0',
      showCom: true,
      asyncUrl: this.URL.syncMainContractData,
      options: [{
        label: '老成本',
        value: 'COST'
      }]
    }
  },
  components: {
    UploadInfoCom,
    TableList,
    SearchInput,
    SwitchTable
  },
  methods: {
    uploadSuccessCallBack() {
      this.init()
    },
    // 切换列表
    changeAsyncType(val) {
      this.showCom = false
      this.listType = val === 'Y' ? '1' : '0'
      this.pageNum = 1
      this.getQueryCostContractList(this.listType)
    },
    // 删除列表
    deleteCostContract(id) {
      const obj = {
        params: {
          id: id
        }
      }
      return this.$request.post(this.URL.deleteCostContract, obj).then(res => {
        if (res.code === '200') {
          this.$message.success('删除成功')
          this.init()
        }
      })
    },
    // 取消同步
    unSyncCostContract(id) {
      var obj = {
        params: id
      }
      this.$request.post(this.URL.unSyncCostContract, obj).then(res => {
        if (res.code === '200') {
          this.$message.success(res.data.message)
          this.init()
        }
      })
    },
    // 列表操作
    operate(type, obj) {
      if (type === 'delete') {
        this.deleteCostContract(obj.id)
      } else if (type === 'cancel') {
        this.unSyncCostContract(obj.id)
      }
    },
    // 分页-切换页码
    currentPageChange(val) {
      this.pageNum = val
      this.getQueryCostContractList()
    },
    // 分页-却换pageSize
    pageSizeChange(val) {
      this.pageSize = val
      this.init()
    },
    // 搜索
    searchInput(val) {
      this.companyName = val
      this.init()
    },    
    // 查询列表数据
    getQueryCostContractList() {
      // 1-已同步 0-未同步
      var obj = {
        params: {
          name: this.companyName,
          type: this.listType,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
      this.$request.post(this.URL.queryCostContractList, obj).then(res => {
        console.log('打印请求结果', res)
        this.showCom = true
        if (res.code === '200') {
          this.total = Number(res.data.contractInfo.total)
          var obj = {unsynchronized: '0', synchronization: '0'}
          if (this.listType === '1') {
            // 同步
            obj.synchronization = this.total
            obj.unsynchronized = res.data.count - this.total
          } else {
            // 未同步
            obj.unsynchronized = this.total
            obj.synchronization = res.data.count - this.total
          }
          this.asyncInfo = obj
          this.dataList = res.data.contractInfo.list
        }
      })
    },
    init() {
      this.pageNum = 1
      this.getQueryCostContractList()
    }
  },
  mounted() {
    this.init()
  }
};
</script>
<style lang="less" scoped>
</style>