<template>
  <div class='switch-table'>
    <div :class="tableType === 'N' ? 'choose' : 'un-choose'" @click="switchTable('N')">
      <span>{{ asyncInfo.unsynchronized }}</span>
      <span>未同步数据(条)</span>
    </div>
    <div :class="tableType === 'Y' ? 'choose' : 'un-choose'" @click="switchTable('Y')">
      <span>{{ asyncInfo.synchronization }}</span>
      <span>已同步数据(条)</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchTable',
  data() {
    return {
      tableType: 'N'
    }
  },
  props: {
    asyncInfo: {
      type: Object,
      default: () => {
        return {unsynchronized: '0', synchronization: '0'}
      }
    },
  },
  computed: {
  },
  watch: {
  },
  methods: {
    switchTable(val) {
      this.tableType = val
      this.$emit('changeAsyncType', val)
    }
  },
  mounted() {
  },
  updated() {
  }
}
</script>

<style lang="less" scoped>
.switch-table{
  width: 100%;
  height: 48px;
  padding: 0 18px;
  box-sizing: border-box;
  margin-bottom: 12px;
  &>div{
    height: 48px;
    float: left;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &>span:first-child{
      font-size: 18px;
      font-weight: 600;
    }
  }
  &>.un-choose{
    width: 37%;
    background-color: #FAFBFF;
    box-shadow: inset 0px -3px 0px #EAECF5;
    transition: width 0.5s;
  }
  &>.choose{
    width: 63%;
    background-color: #FBF4E5;
    box-shadow: inset 0px -3px 0px #EFD193;
    transition: width 0.5s;
  }
}
</style>
